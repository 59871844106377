<template>
    <div class="popup-item registrations">
        <h4 class="popup-item__title">Регистрация</h4>
        <p class="popup-item__subtitle">
            для получения всех бонусов от LuckyGroup и партнёров пройдите короткую регистрацию
        </p>
        <div class="popup-item__form">
            <Input :value="user.name" 
                    placeholder="Имя"
                    white 
                    :onChangeHandler="val=>{handleInput(val, 'name')}"
                    :styles="style"/>
            <Input :value="user.email" 
                    type="email"
                    placeholder="Эл. почта" 
                    white 
                    :onChangeHandler="val=>{handleInput(val, 'email')}"
                    :styles="style"/>
        </div>
        <div class="registrations__button">
            <span class="registrations__button-switch" @click="$emit('switch', 'Login')">Уже есть аккаунт? Войти в систему</span>
            <Button :click="emitRegistration" :styles="{'font-weight': 600, 'height': '60px', 'width': '290px'}">Зарегистрироваться</Button>
        </div>

    </div>
</template>

<script>
import {popupMixin} from "../../mixins/popup"
import Input from "./Input"
import Button from "./Button"
export default {
    data() {
        return {
            style: {
                'width': "290px"
            },
            user: {
                name: "",
                email: ""
            }
        }
    },
    mixins: [popupMixin],
    components: {
        Input, Button
    },
    methods: {
        handleInput(value, key) {
            this.user[key] = value
        },
        emitRegistration(){
            this.$emit('reg', this.user)
        }
    }
}
</script>

<style lang="scss">
    .registrations {
        &__button {
            margin-top: 40px;
            display: flex;
            position: relative;
            justify-content: center;

            &-switch {
                position: absolute;
                cursor: pointer;
                font-size: 14px;
                top: -30px;
                left: 0;
                right: 0;
                text-align: center;
                color: #AF5B4C;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @media screen and (max-width: 840px) {
            &__form {
                flex-direction: column;
            }
        }
    }
</style>